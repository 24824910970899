import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { contractAddress } from "./data/constants";
import contract from "./contracts/contractabi.json";
import './App.css';

const contractABI = contract;

function App() {
    const [provider, setProvider] = useState(null);
    const [contractInstance, setContract] = useState(null);
    const [account, setAccount] = useState(null);
    const [tokenNumber, setTokenNumber] = useState("");
    const [confirmedToken, setConfirmedToken] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isModalOpen, setModalOpen] = useState(false);
    const [txHash, setTxHash] = useState("");

  useEffect(() => {
    const initialize = async () => {
      try {
        if (window.ethereum) {
          await window.ethereum.request({ method: "eth_requestAccounts" });
          const ethereumProvider = new ethers.providers.Web3Provider(window.ethereum);
          const selectedAccount = ethereumProvider.getSigner();

          setProvider(ethereumProvider);
          setAccount(await selectedAccount.getAddress());
          setContract(new ethers.Contract(contractAddress, contractABI, selectedAccount));
        } else {
          setErrorMessage("MetaMask is not installed");
        }
      } catch (error) {
        console.error(error);
        setErrorMessage(error.message);
      }
    };


  }, []);

  const connectWallet = async () => {
    try {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const ethereumProvider = new ethers.providers.Web3Provider(window.ethereum);
      const selectedAccount = ethereumProvider.getSigner();

      setProvider(ethereumProvider);
      setAccount(await selectedAccount.getAddress());
      setContract(new ethers.Contract(contractAddress, contractABI, selectedAccount));
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message);
    }
  };

  const approveArt = async () => {
    try {
        if (contractInstance) {
            const tx = await contractInstance.approveArtUpdate(true, tokenNumber);
            await tx.wait();
            alert("Art update approved!");
        }
    } catch (error) {
        console.error(error);
        setErrorMessage(error.message);
    }
  };

  const openModal = () => {
      setModalOpen(true);
  }

  const closeModal = () => {
      setModalOpen(false);
      setTxHash("");
  }

  const disconnectWallet = () => {
      setProvider(null);
      setContract(null);
      setAccount(null);
}

const confirmToken = () => {
  if (tokenNumber) {
      setConfirmedToken(true);
  } else {
      setErrorMessage("Please enter a valid token ID.");
  }
};

return (
  <div className="App">
      {!account ? (
          <div>
              <h1>illaz</h1>
              <p>Please connect your wallet to continue.</p>
              <button onClick={connectWallet}>Connect Wallet</button>
          </div>
      ) : (
          <div>
              {!confirmedToken ? (
                  <div>
                      <p>Please enter your token ID:</p>
                      <input
                          type="text"
                          value={tokenNumber}
                          onChange={(e) => setTokenNumber(e.target.value)}
                          placeholder="Token ID"
                      />
                      <button onClick={confirmToken}>Confirm</button>
                  </div>
              ) : (
                  <div>
                      <p>Select to approve art update</p>
                      <button onClick={approveArt}>Approve</button>
                  </div>
              )}
              {txHash && (
                  <div>
                      <p>Transaction successful! View it on <a href={"https://etherscan.io/tx/" + txHash} target="_blank" rel="noopener noreferrer">Etherscan</a>.</p>
                  </div>
              )}
              {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
      )}
      {isModalOpen && (
          <div className="modal">
              <div className="modal-content">
                  <span className="close-button" onClick={() => setModalOpen(false)}>&times;</span>
                  <p>Modal content here...</p>
              </div>
          </div>
      )}
  </div>
);
}

export default App;
